import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useCorrectVehiclePolicyStatus } from "hooks/data/mutations/useCorrectVehiclePolicyStatus";
import { useDeleteVehiclePolicyStatus } from "hooks/data/mutations/useDeleteVehiclePolicyStatus";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import * as yup from "yup";
import { RefProvider } from "../../../contexts/RefProvider";
import { useSelector } from "../../../store/hooks";
import { closeDialog, setOperationRetVal } from "../../../store/timeline";
import { TimelineDialogType } from "../../../types/types";
import { setYupLocale } from "../../../utils/setYupLocale";
import { removeEmpty } from "../../../utils/utils";
import { toast } from "../../FluentToast";
import { FormSpinner } from "../../FormSpinner";
import { transformForServer } from "../../form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../styled";
import { ModifyPolicyStatusPointDialogContents } from "./ModifyPolicyStatusPointDialogContents";
import { modifyPolicyStatusPointDialogConfig } from "./modifyPolicyStatusPointDialogConfig";
import { validationObjectConfig } from "./modifyPolicyStatusPointDialogValidation";
import {
  SignificantValues,
  findSignificantValuesOnGivenDateAndInsuranceLine,
  transformForForm,
} from "./transformForForm";

export enum ModifyPolicyStatusPointDialogType {
  Insert,
  Correct,
  Delete,
  Cancel,
}

export const ModifyPolicyStatusPointDialog = () => {
  const theme = useTheme() as IStyledTheme;

  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const item = useSelector((s) => s.timeline.dialog.item);
  const validFrom = useSelector((s) => s.timeline.dialog.date);
  const validTo = useSelector((s) => s.timeline.dialog.item.endDate);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const phraseActivated = usePhraseActivationStatus().activated;

  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(item, dialogType);
    return yup.object(yupObject);
  }, [t, dialogType, item]);

  const {
    mutate: onCorrectVehiclePolicyStatus,
    isLoading: correctVehiclePolicyStatusInProgress,
  } = useCorrectVehiclePolicyStatus();
  const {
    mutate: onDeleteVehiclePolicyStatus,
    isLoading: deleteVehiclePolicyStatusInProgress,
  } = useDeleteVehiclePolicyStatus();

  const handleSuccess = (res) => {
    dispatch(setOperationRetVal(res?.data));
    toast.success(t("bfm.success"));
    dispatch(closeDialog());
  };

  const isLoading =
    correctVehiclePolicyStatusInProgress || deleteVehiclePolicyStatusInProgress;

  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const timelineData = useSelector((s) => s.timeline.timeline.data);

  const taxonomy = useSelector((s) => s.taxonomy);
  const insurerList = useSelector((s) => s.vehicle.insurerList);
  const rowVersion = useSelector((s) => s.vehicle.vehicle?.rowVersion);

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: modifyPolicyStatusPointDialogConfig,
    }) as any;
    result = removeEmpty(result);
    let objectToSend: any = {
      fleetId: vehicle.fleetId as any,
      vehicleId: vehicle.vehicleId as any,
      vehiclePolicyStatusId: item?.associatedObject?.statusId as any,
      vehiclePolicyStatusCode: item?.associatedObject?.statusCode,

      body: {
        ...result,
        vehiclePolicyStatusCode: item?.associatedObject?.statusCode,
      },
    };
    if (dialogType === TimelineDialogType.CORRECT_POLICY_STATUS) {
      const values: SignificantValues =
        findSignificantValuesOnGivenDateAndInsuranceLine(
          false,
          item,
          timelineData,
          data.vehicleLastPolicyStatusDate,
          data.vehicleLastPolicyStatusTime
        );
      onCorrectVehiclePolicyStatus(
        {
          rowVersion,
          params: {
            //rowVersion: item.rowVersion,
            fleetId: vehicle.fleetId,
            vehicleId: vehicle.vehicleId,
            vehiclePolicyStatusId: data.vehiclePolicyStatusId,
            body: {
              validFromDateTime: values.validFromDateTime,

              vehiclePremiumId: values.vehiclePremiumId,
              vehiclePremiumVersionId: values.vehiclePremiumVersionId,

              vehiclePolicyStatusCode: data.vehiclePolicyStatusCode.value,
              comment: data.comment,
            },
          },
        },
        { onSuccess: handleSuccess }
      );
    }

    if (dialogType === TimelineDialogType.DELETE_POLICY_STATUS) {
      onDeleteVehiclePolicyStatus(
        {
          rowVersion,
          params: objectToSend,
        },
        { onSuccess: handleSuccess }
      );
    }
  };
  const Title = (
    <Flex>
      <Icon
        iconName="CircleCorrectition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>
        {dialogType === TimelineDialogType.CORRECT_POLICY_STATUS
          ? t("bfm.correctPolicyStatusDate")
          : null}

        {dialogType === TimelineDialogType.DELETE_POLICY_STATUS
          ? t("bfm.deletePolicyStatusChange")
          : null}
      </div>
    </Flex>
  );

  const initialValues = useMemo(() => {
    let initObj = transformForForm({
      obj: {
        vehiclePolicyStatusCode: item?.associatedObject?.statusCode,
        vehicleLastPolicyStatusDate: item?.associatedObject.statusDate,
        vehicleLastPolicyStatusTime: item?.associatedObject.statusDate,
        vehiclePolicyStatusId: item?.associatedObject?.statusId,
        validFromDate: validFrom,
        validToDate: validTo,
      },
      config: modifyPolicyStatusPointDialogConfig,
      t,
      taxonomy,
      insurerList,
      possibleMainVehicles: null,
    });
    initObj = {
      ...initObj,
    };
    return initObj;
  }, [t, taxonomy, insurerList, item, validFrom, validTo]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={400}
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <ModifyPolicyStatusPointDialogContents
                  dialogType={dialogType}
                />
                <DialogFooter>
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};
