import {
  DatePicker,
  IComboBox,
  Icon,
  Label,
  TimePicker,
} from "@fluentui/react";
import { FieldContainer } from "components/controls/FieldContainer";
import { useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { VSpace } from "../Spacer";
import { ErrorMessage } from "../styled";
import { DateTimeFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: DateTimeFieldConfig };

const datePickerStyles = (theme, isMarked) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: "transparent !important",
      ...(isMarked && { background: theme.palette.yellowLight }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-fieldGroup::before": {
      display: "none",
    },
    ".ms-TextField-suffix": {
      //Cancel Icon
      marginRight: "30px",
      background: "none",
      cursor: "pointer",
    },
    ".ms-TextField-fieldGroup input": {
      background: "transparent !important",
      ...(isMarked && theme.isDark && { color: "black" }),
    },
    ".ms-ComboBox::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-ComboBox.is-disabled::after": {
      background: "transparent",
    },
    ".ms-ComboBox, is-disabled": {
      background: "transparent",
    },
    ".ms-ComboBox-Input, ": {
      background: "transparent",
    },
  },
  icon: { right: "0" },
});

export const DateTimeField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name,
    timeName,
    label,
    isVisible = true,
    isDisabled,
    isRequired,
    renderField,
    setFieldValue,
    errors,
    defaultValue,
    defaultTimeValue,
    validate,
  } = config;
  const { t } = useTranslation();

  const theme = useTheme();
  const [field, { error }] = useField({ name, validate });

  const [value, setValue] = useState(defaultValue);
  const [timeValue, setTimeValue] = useState(defaultTimeValue);

  const onFormatDate = (date?: Date): string => {
    return !date
      ? ""
      : ((date.getDate() + "").length === 1 ? "0" : "") +
          date.getDate() +
          "." +
          ((date.getMonth() + 1 + "").length === 1 ? "0" : "") +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
  };
  const onParseDateFromString = useCallback(
    (newValue: string): Date => {
      const previousValue = value || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year += previousValue.getFullYear();
      }
      return new Date(year, month, day);
    },
    [value]
  );

  useEffect(() => {
    setFieldValue(name, defaultValue);
    setFieldValue(timeName, defaultTimeValue);
  }, []);

  const onTimePickerChange = React.useCallback(
    (_ev: React.FormEvent<IComboBox>, date: Date) => {
      if (date.toString() !== "Invalid Date") {
        setTimeValue(date);
        setFieldValue(timeName, date);
      } else {
        setTimeValue(timeValue);
        setFieldValue(timeName, timeValue);
      }
    },
    []
  );

  if (!isVisible) return null;
  const labelRender = (
    <Label required={isRequired} disabled={isDisabled}>
      {t(label)}
    </Label>
  );
  const fieldRender = (
    <div style={{ display: "flex", columnGap: 10 }}>
      <DatePicker
        id={name}
        onSelectDate={(value) => {
          setValue(value);
          setFieldValue(name, value);
        }}
        styles={datePickerStyles(theme, false)}
        textField={{
          name: name,
          required: isRequired,

          onRenderSuffix: () => (
            <Icon
              style={{
                visibility: value && !isDisabled ? "visible" : "hidden",
              }}
              iconName="Cancel"
              onClick={() => {
                setValue(null);
                setFieldValue(name, null);
              }}
            />
          ),
          styles: { suffix: { padding: "0 4px" } },
        }}
        formatDate={onFormatDate}
        disabled={isDisabled}
        value={value}
        allowTextInput={true}
        disableAutoFocus={true}
        parseDateFromString={onParseDateFromString}
      />
      <TimePicker
        styles={datePickerStyles(theme, false)}
        placeholder="Select a time"
        // dateAnchor={value}
        value={timeValue}
        required={isRequired}
        onChange={onTimePickerChange}
        ariaLabel="Time picker"
        calloutProps={{ styles: { root: { height: "300px" } } }}
      />
    </div>
  );

  const errorRender = errors && errors[name] && (
    <>
      <ErrorMessage id={field.name + "_error"}>{errors[name]}</ErrorMessage>
    </>
  );

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={label}
        isReadOnly={true}
      >
        {labelRender}
        {renderField ? renderField({ field: fieldRender }) : fieldRender}
        {errorRender}
      </FieldContainer>
      <VSpace height={10} />
    </div>
  );
};

export default React.memo(DateTimeField);
