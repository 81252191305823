import {
  getCubicCapacityCodeByNumber,
  getMaxGrossWeightKgRangeCodeByNumber,
  getNumberOfSeatsMopedCodeByNumber,
  getNumberOfSeatsRangeCodeByNumber,
  getWeightCapacityCodeByNumber,
} from "form/util";
import { useGetFleetVehicleDefaultSettings } from "hooks/data/queries/getFleetVehicleDefaultSettings";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  resetDialogDefaultValue,
  setDialogDefaultValueOperationResult,
} from "store/fleetForm";
import {
  FleetDefaultSetting,
  FleetHullCalculationDefaultSettingType,
  PremiumType,
} from "types/types";
import { isStringEmpty, stringToNumber } from "utils/utils";
import { toast } from "../../../../components/FluentToast";
import { useSelector } from "../../../../store/hooks";

export const useDefaultSettings = () => {
  const { fleetId } = useParams() as any;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);

  const { data: defaultSettings } = useGetFleetVehicleDefaultSettings(fleetId);

  const formValues = useSelector(
    (s) => s.fleetForm.defaultValueOperation.formValues
  );

  const vehicleType = formValues?.vehicleType;

  const premiumType = formValues?.premiumType;
  const validFromDate = formValues?.validFromDate;

  const weightCapacityKg = formValues?.weightCapacityKg;
  const numberOfSeatsMoped = formValues?.numberOfSeatsMoped;

  const carUsage = formValues?.carUsage;
  const listPriceType = formValues?.listPriceType;
  const listPrice = formValues?.listPrice;
  const specialEquipment = formValues?.specialEquipment;
  const powerKw = formValues?.powerKw;
  const emptyWeightKg = formValues?.emptyWeightKg;

  const cubicCapacityCcm = formValues?.cubicCapacityCcm;
  const defaultValueOperation = useSelector(
    (s) => s.fleetForm.defaultValueOperation
  );
  useEffect(() => {
    if (
      defaultValueOperation.fieldNames &&
      defaultValueOperation.formValues &&
      !defaultValueOperation.results
    ) {
      const fieldNames = defaultValueOperation.fieldNames;
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;
      const isTradeLicensePlate = formValues.isTradeLicensePlate;

      const fieldNameMapping = {
        annualGrossPremium: "annualGrossPremium",
        deductible: "deductible",
        calculation: "calculation",
      };
      let results: any = {};
      for (const fieldName of fieldNames) {
        const params = {
          attributeName: fieldNameMapping[fieldName],
          premiumType: premiumType ? premiumType.value : null,
          defaultSettings: defaultSettings.map((setting) => {
            return {
              ...setting,
              settingCriteria: {
                ...setting.settingCriteria,
                carUsageCodes: setting.settingCriteria.carUsageCodes || [],
                cubicCapacityCcmRangeCodes:
                  setting.settingCriteria.cubicCapacityCcmRangeCodes || [],
                emptyWeightKgs: setting.settingCriteria.emptyWeightKgs || [],
                isTradeLicensePlate:
                  setting.settingCriteria.isTradeLicensePlate || false,
                listPrices: setting.settingCriteria.listPrices || [],
                maxGrossWeightKgRangeCodes:
                  setting.settingCriteria.maxGrossWeightKgRangeCodes || [],
                numberOfSeatsMopedCodes:
                  setting.settingCriteria.numberOfSeatsMopedCodes || [],
                numberOfSeatsRangeCodes:
                  setting.settingCriteria.numberOfSeatsRangeCodes || [],
                powerKws: setting.settingCriteria.powerKws || [],
                vehicleTypeCodes:
                  setting.settingCriteria.vehicleTypeCodes || [],
                weightCapacityKgRangeCodes:
                  setting.settingCriteria.weightCapacityKgRangeCodes || [],
              },
            };
          }),
          formValues,
          insurerPartnerNumber: insurer,
          vehicleType: vehicleType ? vehicleType.value : null,
          carUsage: carUsage ? carUsage.value : null,
          weightCapacityKg: weightCapacityKg
            ? weightCapacityKg.value + ""
            : null,
          powerKw: powerKw ? powerKw.value + "" : null,
          cubicCapacityCcm: cubicCapacityCcm
            ? cubicCapacityCcm.value + ""
            : null,
          numberOfSeatsMoped: numberOfSeatsMoped
            ? numberOfSeatsMoped.value + ""
            : null,
          numberOfSeatsRange: numberOfSeatsMoped
            ? numberOfSeatsMoped.value + ""
            : null,
          listPriceType: listPriceType ? listPriceType.value : null,
          listPrice: listPrice,
          taxonomy: taxonomy,
          isTradeLicensePlate: isTradeLicensePlate,
          emptyWeightKg: emptyWeightKg?.toString() || null,
        };
        let premium = determineDefaultValue(params);

        // if (!premium) {
        //   premium = "";
        // }

        if (params.attributeName === "annualGrossPremium") {
          let premiumString = null;
          try {
            premiumString = premium.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            results[fieldName] = premiumString.replaceAll(".", ",");
          } catch (ex) {
            //
          }
        }

        if (params.attributeName === "deductible") {
          let premiumString = null;
          try {
            let premiumJson = JSON.parse(premium as string);
            if (premiumJson.hullDeductibleMinAmount) {
              premiumString =
                premiumJson.hullDeductibleMinAmount.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              results["hullDeductibleMinAmount"] = premiumString;
            }
            if (premiumJson.hullDeductibleMaxAmount) {
              premiumString =
                premiumJson.hullDeductibleMaxAmount.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              results["hullDeductibleMaxAmount"] = premiumString;
            }
            if (premiumJson.hullDeductiblePercent) {
              premiumString = premiumJson.hullDeductiblePercent.toLocaleString(
                "de-DE",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              );
              premiumString = premiumString + "";
              results["hullDeductiblePercent"] = premiumString;
            }
            if (premiumJson.hullDeductibleComment) {
              results["hullDeductibleComment"] =
                premiumJson.hullDeductibleComment;
            }
          } catch (ex) {
            //
          }
        }

        if (params.attributeName === "calculation") {
          let premiumString = null;
          try {
            let calculationJson = JSON.parse(premium as string);
            const valueType = calculationJson.valueType;
            const premiumRatePercentage = parseFloat(
              (calculationJson.premiumRatePercentage as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );
            const absoulutePremiumValue = parseFloat(
              (calculationJson.absoulutePremiumValue as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );
            const premiumFreeSpecialEquipment = parseFloat(
              (calculationJson.premiumFreeSpecialEquipment as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const gap = parseFloat(
              (calculationJson.gap as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const replacementValue = parseFloat(
              (calculationJson.replacementValue as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const grossNegligence = parseFloat(
              (calculationJson.grossNegligence as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const electroPlusPackage = parseFloat(
              (calculationJson.electroPlusPackage as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const discountForWaiverOfTaxRefund = parseFloat(
              (calculationJson.discountForWaiverOfTaxRefund as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );
            const listPriceValue = parseFloat(
              (listPrice + "")?.replaceAll(".", "").replaceAll(",", ".")
            );
            if (!listPriceValue) continue;
            const specialEquipmentValue = parseFloat(
              ((specialEquipment ? specialEquipment : 0) + "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );
            //(Listprice + special equipment - premium-free special equipment) x Premium rate + Surcharge for hull additional covers - Discount for waiver of tax refund = hull premium
            let calculatedPremium = 0;
            if (
              valueType ===
              FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
            ) {
              calculatedPremium = absoulutePremiumValue;
            } else {
              calculatedPremium =
                listPriceValue +
                specialEquipmentValue -
                (premiumFreeSpecialEquipment > specialEquipmentValue
                  ? specialEquipmentValue
                  : premiumFreeSpecialEquipment);
              calculatedPremium =
                calculatedPremium * (premiumRatePercentage / 100.0);
            }
            calculatedPremium = calculatedPremium * (1 + gap / 100.0);
            calculatedPremium =
              calculatedPremium * (1 + replacementValue / 100.0);
            calculatedPremium =
              calculatedPremium * (1 + grossNegligence / 100.0);
            calculatedPremium =
              calculatedPremium * (1 + electroPlusPackage / 100.0);
            calculatedPremium =
              calculatedPremium * (1 - discountForWaiverOfTaxRefund / 100.0);

            premiumString = calculatedPremium.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            results["annualGrossPremium"] = premiumString;
          } catch (ex) {
            //
          }
        }
      }
      if (Object.keys(results).length === 0) {
        !defaultValueOperation?.noMessage &&
          toast.info(t("bfm.fillInFleetDefaultsValueNoValue.label"));
        dispatch(resetDialogDefaultValue(null));
      } else {
        !defaultValueOperation?.noMessage &&
          toast.success(t("bfm.fillInFleetDefaultsValueSuccess.label"));
      }

      dispatch(setDialogDefaultValueOperationResult(results));
    }
  }, [defaultValueOperation]);
};

const determineDefaultValue = (params) => {
  const defaultSettings: FleetDefaultSetting[] = params.defaultSettings;
  const formValues = params.formValues;
  // Filter default settings by attribute name and premium type
  let interestingDefaultSettings = defaultSettings
    .filter((ds) => ds.attributeName === params.attributeName)
    .filter((ds) => ds.premiumType === params.premiumType)
    .filter(
      (ds) =>
        !!ds.settingCriteria?.isTradeLicensePlate ===
        !!params.isTradeLicensePlate
    );

  // Filter default settings by insurer
  if (!!!params.insurerPartnerNumber) return null;
  interestingDefaultSettings = interestingDefaultSettings.filter(
    (ds) => ds.insurerPartnerNumber === params.insurerPartnerNumber
  );

  // Filter default settings by main group
  // if (!!!params.vehicleMainGroup) return null;
  // interestingDefaultSettings = interestingDefaultSettings.filter(
  //   (ds) => ds.vehicleMainGroup === params.vehicleMainGroup
  // );
  if (
    formValues.insurerGroupPolicyNumber &&
    formValues.insurerGroupPolicyNumber !== null
  ) {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        ds.insurerGroupPolicyNumber === null ||
        ds.insurerGroupPolicyNumber ===
          formValues.insurerGroupPolicyNumber.value
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        ds.insurerGroupPolicyNumber === undefined ||
        ds.insurerGroupPolicyNumber === null
    );
  }

  interestingDefaultSettings.sort((a, b) => {
    if (
      a.insurerGroupPolicyNumber == null &&
      b.insurerGroupPolicyNumber != null
    ) {
      return 1; // a should come after b
    } else if (
      a.insurerGroupPolicyNumber != null &&
      b.insurerGroupPolicyNumber == null
    ) {
      return -1; // a should come before b
    } else {
      return 0; // keep the original order between two non-null or two null values
    }
  });

  if (params.vehicleType) {
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) =>
      ds.settingCriteria.vehicleTypeCodes.includes(params.vehicleType)
    );
  }

  interestingDefaultSettings = interestingDefaultSettings.filter(
    (ds) =>
      !ds.settingCriteria.carUsageCodes ||
      ds.settingCriteria.carUsageCodes.length === 0 ||
      ds.settingCriteria.carUsageCodes.includes(params.carUsage)
  );

  interestingDefaultSettings.sort(
    nullsLast("settingCriteria.carUsageCodes", true)
  );

  // If weight capacity is specified Leave only those default settings
  // where weight capacity range is not set or it matches specified value
  if (!isStringEmpty(params.weightCapacityKg)) {
    const parsedWeightCapacityKg = stringToNumber(params.weightCapacityKg);
    const weightCapacityTaxonomyCode = getWeightCapacityCodeByNumber(
      Number(parsedWeightCapacityKg)
    );
    const weightCapacityTaxonomyObject =
      params.taxonomy.WeightCapacityKgRange.byCode[weightCapacityTaxonomyCode];
    if (weightCapacityTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.settingCriteria.weightCapacityKgRangeCodes ||
          ds.settingCriteria.weightCapacityKgRangeCodes.length === 0 ||
          ds.settingCriteria.weightCapacityKgRangeCodes.includes(
            weightCapacityTaxonomyObject.id
          )
      );
    }
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.weightCapacityKgRangeCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.weightCapacityKgRangeCodes &&
          ds.settingCriteria.weightCapacityKgRangeCodes.length > 0
        )
    );
  }

  // If powerKw is specified Leave only those default settings
  // where powerKw range is not set or it matches specified value

  if (!isStringEmpty(params.powerKw)) {
    const formattedPowerKw = Number(params.powerKw);
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      return (
        !ds.settingCriteria.powerKws ||
        ds.settingCriteria.powerKws.length === 0 ||
        (formattedPowerKw >= ds.settingCriteria.powerKws[0].from &&
          formattedPowerKw <= ds.settingCriteria.powerKws[0].to)
      );
    });
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.powerKws", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.powerKws || ds.settingCriteria.powerKws.length === 0
    );
  }

  if (!isStringEmpty(params.emptyWeightKg)) {
    const formattedEmptyWeightKg = Number(params.emptyWeightKg);

    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      return (
        !ds.settingCriteria.emptyWeightKgs ||
        ds.settingCriteria.emptyWeightKgs.length === 0 ||
        (formattedEmptyWeightKg >= ds.settingCriteria.emptyWeightKgs[0].from &&
          formattedEmptyWeightKg <= ds.settingCriteria.emptyWeightKgs[0].to)
      );
    });
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.emptyWeightKgs", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.emptyWeightKgs ||
        ds.settingCriteria.emptyWeightKgs.length === 0
    );
  }

  // If maxGrossWeightKgRangeCodes is specified Leave only those default settings
  // where maxGrossWeightKgRangeCodes range is not set or it matches specified value
  // If weight capacity is specified Leave only those default settings
  // where weight capacity range is not set or it matches specified value
  if (!isStringEmpty(params.maxGrossWeightKg)) {
    const parsedMaxGrossWeightKg = stringToNumber(params.maxGrossWeightKg);
    const maxGrossWeightKgTaxonomyCode = getMaxGrossWeightKgRangeCodeByNumber(
      Number(parsedMaxGrossWeightKg)
    );
    const maxGrossWeightTaxonomyObject =
      params.taxonomy.MaxGrossWeightKgRange.byCode[
        maxGrossWeightKgTaxonomyCode
      ];
    if (maxGrossWeightTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.settingCriteria.maxGrossWeightKgRangeCodes ||
          ds.settingCriteria.maxGrossWeightKgRangeCodes.length === 0 ||
          ds.settingCriteria.maxGrossWeightKgRangeCodes.includes(
            maxGrossWeightTaxonomyObject.id
          )
      );
    }
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.maxGrossWeightKgRangeCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.maxGrossWeightKgRangeCodes &&
          ds.settingCriteria.maxGrossWeightKgRangeCodes.length > 0
        )
    );
  }
  // If cubicCapacityCcm is specified Leave only those default settings
  // where cubicCapacityCcm range is not set or it matches specified value
  if (!isStringEmpty(params.cubicCapacityCcm)) {
    const parsedCubicCapacityCcm = stringToNumber(params.cubicCapacityCcm);
    const parsedCubicCapacityCcmTaxonomyCode = getCubicCapacityCodeByNumber(
      Number(parsedCubicCapacityCcm)
    );
    const cubicCapacityCcmTaxonomyObject =
      params.taxonomy.CubicCapacityCcmRangeHg1.byCode[
        parsedCubicCapacityCcmTaxonomyCode
      ];
    if (cubicCapacityCcmTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.settingCriteria.cubicCapacityCcmRangeCodes ||
          ds.settingCriteria.cubicCapacityCcmRangeCodes.length === 0 ||
          ds.settingCriteria.cubicCapacityCcmRangeCodes.includes(
            cubicCapacityCcmTaxonomyObject.id
          )
      );
    }
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.cubicCapacityCcmRangeCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.cubicCapacityCcmRangeCodes &&
          ds.settingCriteria.cubicCapacityCcmRangeCodes.length > 0
        )
    );
  }

  // If numberOfSeatsRange is specified Leave only those default settings
  // where numberOfSeatsRange range is not set or it matches specified value
  if (!isStringEmpty(params.numberOfSeatsRange)) {
    const parsedNumberOfSeatsRange = stringToNumber(params.numberOfSeatsRange);
    const parsedNumberOfSeatsRangeTaxonomyCode =
      getNumberOfSeatsRangeCodeByNumber(Number(parsedNumberOfSeatsRange));
    const numberOfSeatsRangeTaxonomyObject =
      params.taxonomy.NumberOfSeatsRange.byCode[
        parsedNumberOfSeatsRangeTaxonomyCode
      ];
    if (numberOfSeatsRangeTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.settingCriteria.numberOfSeatsRangeCodes ||
          ds.settingCriteria.numberOfSeatsRangeCodes.includes(
            numberOfSeatsRangeTaxonomyObject.id
          )
      );
    }
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.numberOfSeatsRangeCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.numberOfSeatsRangeCodes &&
          ds.settingCriteria.numberOfSeatsRangeCodes.length > 0
        )
    );
  }

  // If numberOfSeatsMoped is specified Leave only those default settings
  // where numberOfSeatsMoped range is not set or it matches specified value

  if (!isStringEmpty(params.numberOfSeatsMoped)) {
    const parsedNumberOfSeatsMoped = stringToNumber(params.numberOfSeatsMoped);
    const parsedNumberOfSeatsMopedTaxonomyCode =
      getNumberOfSeatsMopedCodeByNumber(Number(parsedNumberOfSeatsMoped));
    const numberOfSeatsMopedTaxonomyObject =
      params.taxonomy.NumberOfSeatsMoped.byCode[
        parsedNumberOfSeatsMopedTaxonomyCode
      ];
    if (numberOfSeatsMopedTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.settingCriteria.numberOfSeatsMopedCodes ||
          ds.settingCriteria.numberOfSeatsMopedCodes.includes(
            numberOfSeatsMopedTaxonomyObject.id
          )
      );
    }
    interestingDefaultSettings.sort(
      nullsLast("settingCriteria.numberOfSeatsMopedCodes", true)
    );
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !!!(
          ds.settingCriteria.numberOfSeatsMopedCodes &&
          ds.settingCriteria.numberOfSeatsMopedCodes.length > 0
        )
    );
  }

  if (
    (params.premiumType ===
      params.taxonomy.PremiumType.byCode[PremiumType.HULL].id ||
      params.premiumType ===
        params.taxonomy.PremiumType.byCode[PremiumType.HULL_BASIC].id) &&
    (params.attributeName === "calculation" ||
      params.attributeName === "annualGrossPremium")
  ) {
    if (!!!params.listPriceType) return null;
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        ds.settingCriteria.listPrices &&
        ds.settingCriteria.listPrices[0].type === params.listPriceType
    );
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.listPrices ||
        ds.settingCriteria.listPrices.length === 0 ||
        ds.settingCriteria.listPrices[0].min === null ||
        ds.settingCriteria.listPrices[0].min <= params.listPrice
    );
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) =>
        !ds.settingCriteria.listPrices ||
        ds.settingCriteria.listPrices.length === 0 ||
        ds.settingCriteria.listPrices[0].max === null ||
        ds.settingCriteria.listPrices[0].max >= params.listPrice
    );
  }
  if (!formValues.validFromDate) return null;

  interestingDefaultSettings.sort(
    (a, b) =>
      new Date(b.validFromDate).getTime() - new Date(a.validFromDate).getTime()
  );
  interestingDefaultSettings = interestingDefaultSettings.filter((item) => {
    return (
      new Date(item.validFromDate).getTime() <=
      new Date(formValues.validFromDate).getTime()
    );
  });

  if (interestingDefaultSettings.length > 0) {
    return interestingDefaultSettings[0].attributeValue;
  }
  return null;
};

function nullsLast(attributePath, ascending) {
  const attributes = attributePath.split(".");

  return function (a, b) {
    const aValue = attributes.reduce((obj, key) => obj?.[key], a);
    const bValue = attributes.reduce((obj, key) => obj?.[key], b);

    if (aValue === null || aValue === undefined) return 1;
    if (bValue === null || bValue === undefined) return -1;

    const aLength = aValue.length;
    const bLength = bValue.length;

    if (ascending) {
      return aLength - bLength;
    } else {
      return bLength - aLength;
    }
  };
}
