import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserRole } from "types/types";
import { useSelector } from "../../store/hooks";
import { selectSortInsurersPage } from "../../store/insurersPage";
import { DeleteDialog } from "./DeleteDialog";
import InsurerListTable from "./InsurerList/InsurerListTable";
import { Navigation } from "./Navigation";

import { useGetInsurerList } from "hooks/data/queries/useGetInsurerList";
import { useLocation } from "react-router-dom";
import { defaultColumns } from "./InsurerList/columns";
import useFilteredInsurerList from "./useFilteredInsurerList";

export const Insurers = () => {
  const { t } = useTranslation();

  const userCountryCode = useSelector((s) => s.auth.userCountryCode);

  const { data: insurerSettings } = useGetInsurerList(null);

  const sortValue = useSelector(selectSortInsurersPage);

  const insurers = useFilteredInsurerList();

  const userRole = useSelector((s) => s.auth.userRole);

  const location = useLocation();
  const updatedInsurerSetting = location.state || {};

  const insurersWithSettings = useMemo(() => {
    return insurers?.map((insurer) => {
      const insurerSettingsHistory = insurerSettings
        ?.filter(
          (sett) => sett.insurerPartnerNumber === insurer.insurerInternalNumber
        )
        .map((sett) => {
          let obj = { ...sett };
          if (updatedInsurerSetting && sett.id === updatedInsurerSetting?.id) {
            obj = { ...sett, ...updatedInsurerSetting };
          }
          return {
            ...obj,
            validFromDate:
              sett.validFromDate === "0001-01-01T00:00:00Z"
                ? new Date(0)
                : new Date(sett.validFromDate),
          };
        })
        .sort((a, b) => {
          return b.validFromDate.getTime() - a.validFromDate.getTime();
        });
      return {
        ...insurer,
        insurerSettingsHistory,
      };
    });
  }, [insurers, insurerSettings]);

  const detailsListColumns = useMemo(() => {
    const filteredTableColumns = defaultColumns.filter((c) => {
      if (
        userRole === undefined ||
        userRole === null ||
        userRole === UserRole.external
      ) {
        return c.key !== "options";
      }
      return true;
    });
    const withTranslatedHeader = filteredTableColumns
      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === sortValue.key && column.isSortable) {
          iconName =
            sortValue.key === column.key
              ? sortValue!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.name),
          iconName: iconName,
        };
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
    return [...withTranslatedHeader];
  }, [userRole, sortValue, t]);
  return (
    <>
      <Navigation />
      <InsurerListTable
        items={insurersWithSettings}
        columns={detailsListColumns}
      />
      <DeleteDialog />
    </>
  );
};
